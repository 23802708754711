import "./Gallery.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

function Gallery() {

  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;
  console.log(accessToken); 
  const [posts, setPosts] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchInstagramPosts = async (url) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await axios.get(url);
      let newPosts = response.data.data;

      // Fetch full carousel images for "CAROUSEL_ALBUM" posts
      const carouselPosts = newPosts.filter(
        (post) => post.media_type === "CAROUSEL_ALBUM"
      );

      const carouselRequests = carouselPosts.map(async (post) => {
        const carouselResponse = await axios.get(
          `https://graph.instagram.com/${post.id}/children?fields=id,media_type,media_url&access_token=${accessToken}`
        );
        return { ...post, children: carouselResponse.data.data };
      });

      const updatedCarouselPosts = await Promise.all(carouselRequests);

      newPosts = newPosts.map((post) =>
        post.media_type === "CAROUSEL_ALBUM"
          ? updatedCarouselPosts.find((cPost) => cPost.id === post.id) || post
          : post
      );

      const uniquePosts = [
        ...new Map(
          [...posts, ...newPosts].map((post) => [post.id, post])
        ).values(),
      ];

      setPosts(uniquePosts);
      setNextPage(response.data.paging?.next || null);
    } catch (error) {
      console.error("Error fetching Instagram data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialUrl = `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,thumbnail_url&limit=9&access_token=${accessToken}`;
    fetchInstagramPosts(initialUrl);
  }, []);

  return (
    <div className="App">
      <h1>Our Gallery</h1>
      <div className="instagram-feed">
        {posts.map((post) => (
          <div key={post.id} className="instagram-post">
            <a href={post.permalink} target="_blank" rel="noopener noreferrer">
              {post.media_type === "IMAGE" ? (
                <img src={post.media_url} alt="Instagram post" />
              ) : post.media_type === "VIDEO" ? (
                <video controls>
                  <source src={post.media_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : post.media_type === "CAROUSEL_ALBUM" ? (
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={10}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                >
                  {post.children?.map((child) => (
                    <SwiperSlide key={child.id}>
                      <img src={child.media_url} alt="Carousel item" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : null}
            </a>
          </div>
        ))}
      </div>
      {nextPage && (
        <button
          className="load-more"
          onClick={() => fetchInstagramPosts(nextPage)}
        >
          {loading ? "Loading..." : "Load More"}
        </button>
      )}
    </div>
  );
}

export default Gallery;
